// Finds the first unmatched cue in subtitleCues by matching text content,
// and marks it as matched to prevent reuse.
export const findCueWithTiming = (cueText, subtitleCues, matchedIndices) => {
  const cueIndex = subtitleCues.findIndex(
    (subCue, index) =>
      subCue.text.trim() === cueText.trim() && !matchedIndices.has(index)
  );
  if (cueIndex !== -1) {
    matchedIndices.add(cueIndex);
    return subtitleCues[cueIndex];
  }
  return null;
};

// Maps the GraphQL data to include detailed timing from subtitleCues,
// ensuring cues with the same text are uniquely matched.
export const mapCuesWithDetailedTiming = (paragraphs, subtitleCues) => {
  const matchedIndices = new Set(); // Tracks indices of matched subtitle cues to avoid reuse
  return paragraphs.map(paragraph => ({
    ...paragraph,
    cues: paragraph.cues.map(cue => {
      const matchingCue = findCueWithTiming(
        cue.text,
        subtitleCues,
        matchedIndices
      );
      if (matchingCue) {
        return {
          ...cue,
          startTime: matchingCue.startTime,
          endTime: matchingCue.endTime
        };
      }
      return cue; // Fallback to the original cue if no match found
    })
  }));
};
