import { range } from 'lib/f';

import { Text } from 'components/@tedui';

import Loader from 'components/loaders';
import type React from 'react';
import ParagraphsContainerProps from './ParagraphsContainer.props';

const ParagraphsContainer = ({
  children,
  isLoading,
  translation,
  languageName
}: ParagraphsContainerProps & { children?: React.ReactNode }) => {
  if (isLoading) {
    return (
      <div
        className="flex w-full flex-col gap-6"
        data-testid="paragraphs-container"
      >
        {range(0, 6).map(item => (
          <div key={`transcript-loading-${item}`} className="w-full">
            <Loader.Text
              lines="3"
              color={{
                override: 'rgb(18 18 18 / 12%)'
              }}
            />
          </div>
        ))}
      </div>
    );
  }

  if (!translation) {
    return (
      <div className="mt-10">
        <Text tag="p">
          Sorry, there is no transcript available for{' '}
          <strong className="font-bold">{languageName}.</strong>
        </Text>
        <Text tag="p">Please select another language.</Text>
      </div>
    );
  }

  return (
    <div className="w-full" data-testid="paragraphs-container">
      {children}
    </div>
  );
};

export default ParagraphsContainer;
