import { buildQueryString } from '@aws-sdk/querystring-builder';
import { QueryParameterBag } from '@aws-sdk/types';

const formattedQueryString = (queries: QueryParameterBag): string => {
  if (!queries) return '';

  // Create a copy and remove any undefined, null, or empty values
  const cleanedQueries = Object.entries(queries).reduce(
    (acc, [key, value]) => {
      if (
        value !== undefined &&
        value !== null &&
        value !== '' &&
        key !== 'slug'
      ) {
        acc[key] = String(value);
      }
      return acc;
    },
    {} as Record<string, string>
  );

  if (Object.keys(cleanedQueries).length === 0) return '';

  return `?${buildQueryString(cleanedQueries)}`;
};

export function buildTalkUrl(
  baseUrl: string,
  queries: QueryParameterBag
): string {
  const output = formattedQueryString(queries);
  if (!output) return baseUrl;
  return `${baseUrl}${output}`;
}

export function buildTalkParams(params: Record<string, string>): string {
  const queryParams = new URLSearchParams();

  // Add only defined and non-null params
  Object.entries(params).forEach(([key, value]) => {
    if (
      value !== undefined &&
      value !== null &&
      value !== '' &&
      key !== 'slug'
    ) {
      queryParams.set(key, String(value));
    }
  });

  const queryString = queryParams.toString();
  return queryString ? `?${queryString}` : '';
}
