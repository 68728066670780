import { createIntl } from 'react-intl';

/**
 *
 * @param duration - time in seconds
 * @returns time as a string in HH:MM:SS format
 */
export function getDurationString(duration: number): string {
  const oneMinuteDuration = 60;
  const dateObj = new Date(duration * 1000);
  const hours = dateObj.getUTCHours();
  const h = hours ? `${hours.toString().padStart(2, '0')}:` : '';
  const minutes = dateObj.getUTCMinutes();
  const m =
    duration >= oneMinuteDuration
      ? `${minutes.toString().padStart(2, '0')}:`
      : '';
  const seconds = dateObj.getSeconds();
  const s = seconds.toString().padStart(2, '0');
  return `${h}${m}${s}`;
}

/**
 *
 * @param duration - time in seconds
 * @returns time as a string in HH:MM:SS format
 */
export function getDurationStringWithMinutes(duration: number): string {
  const durationConverted = getDurationString(duration);
  if (durationConverted.length === 2) {
    return `00:${durationConverted}`;
  }
  return durationConverted;
}

/**
 *
 * @param duration - time in seconds
 * @returns time as a string in H hours M minutes S seconds
 */
export function getDurationAriaLabel(duration: number, locale = 'en'): string {
  const { formatMessage: t } = createIntl({ locale });

  const dateObj = new Date(duration * 1000);

  const hours = dateObj.getUTCHours();
  const h = hours
    ? t(
        {
          id: 'duration.hours',
          defaultMessage: '{hours, plural, one {# hour} other {# hours}} '
        },
        { hours }
      )
    : '';

  const minutes = dateObj.getUTCMinutes();
  const m = minutes
    ? t(
        {
          id: 'duration.minutes',
          defaultMessage: '{minutes, plural, one {# minute} other {# minutes}} '
        },
        { minutes }
      )
    : '';

  const seconds = dateObj.getSeconds();
  const s = seconds
    ? t(
        {
          id: 'duration.seconds',
          defaultMessage: '{seconds, plural, one {# second} other {# seconds}}'
        },
        { seconds }
      )
    : '';
  return `${h}${m}${s}`;
}
