import { useRelatedVideosQuery } from 'api';
import { Link } from 'components/router';
import SkeletonImage from 'components/skeleton-loader/image';
import { useSetSourceContext } from 'lib/business/source-context';
import { useRouter } from 'next/router';
import { range, splitAt } from 'rambda';
import { useEffect, useMemo } from 'react';
import { filter, shuffle } from 'lodash-es';
import { Video } from 'api';
import { TalkPageAds } from 'components/ads';
import { useTalkPageContext } from '../../talk-page-context';
import SidebarVideo from '../SidebarVideo';
import RelatedVideosListProps from './RelatedVideosList.props';
import usePersonalizedContent from 'lib/hooks/useSailthru/usePersonalizedContent';
import { usePlayNextTalk } from '../../hooks';

const RELATED_VIDEOS_SAILTHRU_ID = '9b07b404-d26d-11ef-af35-eacb2f1c3c9a';

type HybridRelatedVideosListProps = RelatedVideosListProps & {
  sailthruCount?: number; // number of Sailthru talks to show
};

const HybridRelatedVideosList = ({
  id,
  sailthruCount = 3 // default to 3 if not specified
}: HybridRelatedVideosListProps) => {
  const router = useRouter();
  const { language, playlistSlug } = useTalkPageContext();
  const setSourceContext = useSetSourceContext('content_discovery_context');
  const { playNextTalk } = usePlayNextTalk({ setSourceContext });
  // Regular related videos query
  const {
    data: regularData,
    loading: regularLoading,
    error: regularError
  } = useRelatedVideosQuery({
    variables: { id: Number(id), language, count: 6 },
    ssr: false
  });

  // Sailthru personalized content
  const {
    data: personalizedData,
    refetch: fetchPersonalizedData,
    isFetching: isFetchingPersonalizedData,
    error: personalizedError
  } = usePersonalizedContent(RELATED_VIDEOS_SAILTHRU_ID, { talkId: id });

  useEffect(() => {
    fetchPersonalizedData();
  }, [fetchPersonalizedData, router.asPath]);

  // Combine both sources
  const relatedVideos = useMemo(() => {
    const regularVideos =
      regularData?.videos?.nodes[0]?.relatedVideos?.filter(
        video => video.id !== id
      ) || [];

    const sailthruVideos = filter(
      shuffle(personalizedData),
      item => item.video.id !== id
    )
      .slice(0, sailthruCount)
      .map(item => item.video);

    const TOTAL_VIDEOS = 6;

    // Calculate how many regular videos we need
    const remainingCount = TOTAL_VIDEOS - sailthruVideos.length;

    // Get regular videos to fill the remaining slots
    const regularVideosFallback = regularVideos.slice(0, remainingCount);

    return [...sailthruVideos, ...regularVideosFallback];
  }, [regularData, personalizedData, id, sailthruCount]);

  if (regularError || personalizedError) {
    console.error(regularError ?? personalizedError);
  } else if (regularLoading || isFetchingPersonalizedData) {
    return (
      <>
        {range(0, 5).map(item => (
          <SkeletonImage
            key={`video-loader-${item}`}
            ratio={[16, 5]}
            className="mb-6"
          />
        ))}
      </>
    );
  }

  const renderVideoLink = (
    video: Video | Partial<Video>,
    index: number
  ): React.ReactNode => {
    const href = {
      pathname: router.pathname,
      query: {
        ...router.query,
        slug: video.slug
      }
    };

    return (
      <div key={index}>
        <Link
          onClick={playNextTalk()}
          isZenithLink
          key={video.slug}
          href={href}
          className={`block pr-3 ${index === 0 ? 'pt-0' : 'pt-3'} `}
        >
          <SidebarVideo video={video} />
        </Link>
      </div>
    );
  };

  const [initialVideos, remainderVideos] = splitAt(
    3,
    relatedVideos.map(renderVideoLink) || []
  );

  return (
    <>
      {initialVideos}
      {!playlistSlug && <TalkPageAds.TalkAdSideBarBTF />}
      {remainderVideos}
    </>
  );
};

export default HybridRelatedVideosList;
