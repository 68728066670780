import cx from 'classnames';
import { Icon } from 'components/@tedui';
import { useDidMount } from 'lib/hooks/useDidMount';
import { useIsBreakpointWidth } from 'lib/hooks/useIsBreakpointWidth';
import { minBy } from 'lodash-es';
import { memo, useEffect, useRef, useState } from 'react';
import { Sheet, SheetRef } from 'react-modal-sheet';
import { useOutsideClick } from 'rooks';
import { shallow } from 'zustand/shallow';
import Transcript from '../Transcript';

interface Props {
  isVisible: boolean;
  onToggle: () => void;
}

const SNAP_POINTS = [0.7, 0.65, 0.6, 0.55, 0.5, 0.45, 0.4, 0.35, 0.3];

function TranscriptsWrapper({ isVisible, onToggle }: Props): React.ReactNode {
  const hasMounted = useDidMount();
  const isPhoneWidth = useIsBreakpointWidth({
    size: 'sm',
    breakPointType: 'tui'
  });
  const isTabletPortrait = useIsBreakpointWidth({
    size: 'lg',
    breakPointType: 'tui'
  });
  const isTabletWidth = useIsBreakpointWidth({ size: 'lg' });
  const sheetRef = useRef<SheetRef>(undefined);
  const transcriptRef = useRef<HTMLDivElement>(undefined);
  const isMobileWidth = isTabletWidth || isTabletPortrait || isPhoneWidth;

  useOutsideClick(transcriptRef, onToggle);

  const [snapPoint, setSnapPoint] = useState(SNAP_POINTS[0]);

  const calculateSheetPosition = () => {
    const element = document.getElementById('talk-title');

    if (!element) {
      console.error(`Could not find TED Player`);
      return null;
    }

    const elementRect = element.getBoundingClientRect();
    const distanceFromBottom = window.innerHeight - elementRect.top; // position from the top of the title
    const proportionFromBottom = distanceFromBottom / window.innerHeight;

    const closest = SNAP_POINTS.indexOf(
      minBy(SNAP_POINTS, number => {
        return Math.abs(number - proportionFromBottom);
      })
    );

    const snap = SNAP_POINTS[closest];

    setSnapPoint(snap);
  };

  useEffect(() => {
    if (!hasMounted) return;

    calculateSheetPosition();
    window.addEventListener('resize', calculateSheetPosition);

    return () => {
      window.removeEventListener('resize', calculateSheetPosition);
    };
  }, [hasMounted]);

  return isMobileWidth ? (
    <Sheet
      ref={sheetRef}
      initialSnap={0}
      snapPoints={[snapPoint, 0]}
      isOpen={isVisible && isMobileWidth}
      onClose={onToggle}
    >
      <Sheet.Container>
        <Sheet.Header>
          <div className="flex w-full justify-between p-4">
            <div />
            <div className="mt-3 h-1 w-12 rounded-full bg-textTertiary-onDark" />
            <button type="button" onClick={onToggle}>
              <Icon
                iconName="x"
                className="flex items-center justify-center text-2xl"
              />
            </button>
          </div>
        </Sheet.Header>
        <Sheet.Content>
          <div
            ref={transcriptRef}
            className="w-full overflow-y-auto px-4 lg:overflow-hidden lg:px-0"
          >
            <Transcript />
          </div>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop />
    </Sheet>
  ) : (
    <section className={cx('lg:h-auto', { open: isVisible })}>
      {/* Transcript modal content */}
      <div
        className="w-full overflow-y-auto px-4 lg:overflow-hidden lg:px-0"
        style={{
          marginTop: 50
        }}
      >
        {isVisible && <Transcript />}
      </div>
    </section>
  );
}

export default memo(TranscriptsWrapper, shallow);
