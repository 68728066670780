import cx from 'classnames';
import ISO6391 from 'iso-639-1';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import shallow from 'zustand/shallow';

import { allPass, complement, compose, equals } from 'f';

import { useIsBreakpointWidth } from 'lib/hooks/useIsBreakpointWidth';
import { useValidatedTranscriptQuery } from 'lib/hooks/useValidatedTranscriptQuery';
import { createLogger } from 'lib/logging';
import { isElementInView, timestampToSeconds } from 'lib/util';

import { Text } from 'components/@tedui';
import Icon from 'components/@tedui/Icon';
import Select from 'components/input/select';
import Markdown from 'components/markdown';
import { Link, useRouter } from 'components/router';
import useScrollIntoView from 'components/use-scroll-into-view';
import { Companion } from 'components/video-ads';
import useVideoPlayerStore from 'components/video-player/store';

import { useTalkPageContext } from '../talk-page-context';
import AnchorLink from './AnchorLink';
import ParagraphsContainer from './ParagraphsContainer';
import SectionHeading from './SectionHeading';
import TimecodeButton from './TimecodeButton';
import TranscriptParagraph from './TranscriptParagraph';
import TranslatorPopover from './TranslatorPopover';
import { useLinkTranscript } from './use-link-transcript';
import { mapCuesWithDetailedTiming } from './utils';

import type {
  ITranscriptParagraph,
  MergedTranscript,
  TranscriptCue
} from './types';

interface LanguageOption {
  label: string;
  value: string;
}

const Transcript = () => {
  const isMobile = useIsBreakpointWidth({ size: 'sm', breakPointType: 'ads' });
  const router = useRouter();
  const { slug, asPath: path, language: queryLang } = useTalkPageContext();
  const {
    contentState,
    convertedTime,
    languages,
    nativeLanguage,
    onSeek,
    onTranscriptLanguage,
    subtitleCues,
    allSubtitles
  } = useVideoPlayerStore(
    state => ({
      ...state
    }),
    shallow
  );

  const requestedLanguage = useMemo(() => {
    return { value: queryLang, label: ISO6391.getName(queryLang) };
  }, [queryLang]);

  const [language, setActiveOption] =
    useState<LanguageOption>(requestedLanguage);
  const [activeCue, setActiveCue] = useState<number | null>(null);
  const [mergedTranscript, setMergedTranscript] = useState<MergedTranscript>(
    []
  );
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const transcriptMenuRef = useRef<HTMLDivElement>(null);
  const transcriptRef = useRef<HTMLDivElement>(null);
  const footnoteRefs = useRef<Record<string, HTMLDivElement>>({});
  const paragraphRefs = useRef<Record<number, HTMLDivElement>>({});

  const handleSelectLanguageOption = (value: string) => {
    const newLanguage = languageOptions.find(l => l.value === value);
    if (!newLanguage) return;

    onTranscriptLanguage(value);
    setActiveOption(newLanguage);

    router.replace(
      {
        pathname: router.pathname,
        query: { ...router.query, language: newLanguage.value }
      },
      undefined,
      {
        shallow: true
      }
    );
  };

  const anchor = useMemo(
    () =>
      compose(
        (arr: string[]) => arr[arr.length - 1] || '',
        (str: string) => str.split('#'),
        (str: string) => str || ''
      )(path),
    [path]
  );

  const languageOptions = useMemo(() => {
    if (!languages) {
      return [];
    }

    const options = languages.map(
      ({ endonym: label, languageCode: value }) => ({ label, value })
    );

    if (!options.find(({ value }) => value === requestedLanguage.value)) {
      options.push(requestedLanguage);
    }

    return options.sort((a, b) => (a.label < b.label ? -1 : 1));
  }, [languages, requestedLanguage]);

  const { error, data, loading } = useValidatedTranscriptQuery({
    id: slug,
    language: language.value
  });

  const translation = data?.translation;
  const footnotes = data?.video?.talkExtras?.footnotes;

  const { getFootnoteTimecodeFromParagraph, getParagraphCueTimeFromFootnote } =
    useLinkTranscript(translation?.paragraphs, footnotes);

  const scrollWhen = allPass([
    complement((): boolean => contentState === 'content'),
    complement((): boolean => loading),
    complement((): boolean => !footnotes),
    equals('footnotes')
  ])(anchor);

  const [footnotesRef] = useScrollIntoView({ when: scrollWhen });

  const handleSeek = useCallback(
    (e, time) => {
      e.stopPropagation();
      const seekTime = time;
      onSeek(seekTime);
    },
    [onSeek]
  );

  const handleScrollIntoView = useCallback((elementRef: HTMLDivElement) => {
    if (elementRef && !isElementInView(elementRef)) {
      elementRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, []);

  useEffect(() => {
    if (data?.translation?.paragraphs && allSubtitles) {
      // Get the selected transcript language from the language dropdown
      const transcriptLanguage = language.value;

      // Find the matching subtitle cues based on the selected language
      // If no matching cues are found, use an empty array
      const matchingSubtitleCues = allSubtitles[transcriptLanguage] || [];

      try {
        // Map the transcript paragraphs with the matching subtitle cues
        // to include detailed timing information
        const mappedData = mapCuesWithDetailedTiming(
          data.translation.paragraphs,
          matchingSubtitleCues
        );

        // Update the merged transcript state with the mapped data
        setMergedTranscript(mappedData || []);
      } catch (err) {
        const logger = createLogger('Transcript');
        logger.error('Error mapping transcript data:', err);
        setMergedTranscript([]);
      }
    } else {
      setMergedTranscript([]);
    }
  }, [data, allSubtitles, language]);

  const cueTimes = useMemo(() => {
    if (!Array.isArray(mergedTranscript) || !mergedTranscript.length) return [];

    return mergedTranscript.flatMap((paragraph: ITranscriptParagraph) => {
      if (!paragraph?.cues?.length) return [];

      return paragraph.cues.map((cue: TranscriptCue) => ({
        text: cue.text || '',
        startTime: cue.startTime || 0,
        endTime: cue.endTime || 0
      }));
    });
  }, [mergedTranscript]);

  useEffect(() => {
    if (!cueTimes.length || contentState !== 'content') return;

    const activeCueIndex = cueTimes.findIndex(
      cue =>
        convertedTime >= cue.startTime * 1000 &&
        convertedTime < cue.endTime * 1000
    );

    if (activeCueIndex !== -1) {
      setActiveCue(subtitleCues[activeCueIndex]?.startTime ?? null);
    }
  }, [contentState, convertedTime, cueTimes, subtitleCues]);

  useEffect(() => {
    if (!paragraphRefs.current || !activeCue || contentState !== 'content')
      return;

    const element = paragraphRefs.current[activeCue];
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, [activeCue, contentState]);

  // React to changes in the queryLang and update the language accordingly
  useEffect(() => {
    const newLanguageOption = {
      value: queryLang,
      label: ISO6391.getName(queryLang)
    };

    // Check if the current language options include the new language from the URL
    const languageOptionExists = languageOptions.some(
      option => option.value === queryLang
    );

    // If the language from the URL is not in the current options, add it (or handle accordingly)
    if (!languageOptionExists && queryLang) {
      setActiveOption(newLanguageOption);
      onTranscriptLanguage(queryLang);
    } else {
      setActiveOption(newLanguageOption);
    }
  }, [queryLang, languageOptions, onTranscriptLanguage]);

  if (error) {
    const logger = createLogger('Transcript');
    logger.error(error);
  }

  return (
    <div className="mb-10 w-full">
      <div className="mx-auto mb-10 w-full" ref={transcriptRef}>
        <div className="flex justify-between">
          <h4>
            <span className="text-lg font-bold">
              <FormattedMessage defaultMessage="Transcript" />
            </span>{' '}
            <span className="text-sm font-normal">
              <FormattedMessage
                defaultMessage={`{languages, plural,
                  =0 {}
                  one {(# Language)}
                  few {(# Languages)}
                  many {(# Languages)}
                  other {(# Languages)}
                }`}
                description="Used in the Transcripts header to describe the number of available languages"
                values={{ languages: languages?.length || 0 }}
              />
            </span>
          </h4>
          <div ref={transcriptMenuRef}>
            <button type="button" onClick={() => setIsMenuOpen(o => !o)}>
              <Icon iconName="more-horizontal" />
            </button>
          </div>

          {isMenuOpen && (
            <TranslatorPopover
              targetRef={transcriptMenuRef}
              translator={translation?.translator}
              reviewer={translation?.reviewer}
              isNativeLanguage={nativeLanguage === language.value}
              onClose={() => setIsMenuOpen(false)}
            />
          )}
        </div>
        <div className="mt-4 w-full">
          <div className="w-full max-w-64">
            <Select
              className="rounded-sm"
              data-testid="transcript__language-dropdown"
              onChange={handleSelectLanguageOption}
              value={language.value}
              options={languageOptions}
            />
          </div>
          <div className="mb-8 mt-4 flex min-w-40 justify-between">
            {translation?.translator && (
              <div className="text-xs text-gray-700">
                <a
                  href={translation.translator?.profilePath}
                  className="font-bold hover:underline"
                >
                  {translation.translator?.name?.full},
                </a>{' '}
                <FormattedMessage tagName="span" defaultMessage="Translator" />
              </div>
            )}
            {translation?.reviewer && (
              <div className="text-xs text-gray-700">
                <a
                  href={translation.reviewer?.profilePath}
                  className="font-bold hover:underline"
                >
                  {translation.reviewer?.name?.full},
                </a>{' '}
                <FormattedMessage tagName="span" defaultMessage=" Reviewer" />
              </div>
            )}
          </div>
        </div>

        <Companion
          type="SmallRectangleThin"
          path={process.env.NEXT_PUBLIC_AD_PATH_TALKS || ''}
          identifier="transcript-billboard"
          className={cx('w-full', {
            hidden: !isMobile
          })}
        />

        {footnotes && footnotes?.length > 0 && (
          <AnchorLink
            iconName="arrow-down"
            onClick={() =>
              footnotesRef.current &&
              handleScrollIntoView(footnotesRef.current as HTMLDivElement)
            }
          >
            <FormattedMessage defaultMessage="Footnotes" />
          </AnchorLink>
        )}

        <ParagraphsContainer
          isLoading={loading}
          translation={translation}
          languageName={language.label}
        >
          {Array.isArray(mergedTranscript) &&
            mergedTranscript.map((p: ITranscriptParagraph, idx) => {
              if (!p?.cues?.length) return null;

              const time = p.cues[0]?.startTime ?? 0;
              const footnoteTimecode = getFootnoteTimecodeFromParagraph(p);
              const cueId = `${time}-${p.cues[0]?.text?.slice(0, 10)}-${idx}`;

              return (
                <div
                  ref={el => {
                    if (el && p.cues[0]?.time) {
                      paragraphRefs.current[p.cues[0].time] = el;
                    }
                  }}
                  key={cueId}
                  className="mb-6 w-full"
                >
                  <div className="mb-5 mt-10 flex items-center">
                    <TimecodeButton
                      key={cueId}
                      time={time}
                      onClick={e => handleSeek(e, time)}
                    />
                    {footnoteTimecode != null && (
                      <AnchorLink
                        onClick={() => {
                          const element =
                            footnoteRefs.current[footnoteTimecode];
                          if (element) {
                            handleScrollIntoView(element);
                          }
                        }}
                        className="ml-6"
                      >
                        <FormattedMessage defaultMessage="footnote" />
                      </AnchorLink>
                    )}
                  </div>
                  <TranscriptParagraph
                    activeCue={activeCue ?? 0}
                    cues={p.cues}
                    rtl={translation?.language?.rtl ? 'rtl' : 'ltr'}
                    language={language.value}
                  />
                </div>
              );
            })}
        </ParagraphsContainer>
      </div>

      {footnotes && footnotes?.length > 0 && (
        <div
          ref={footnotesRef as React.RefObject<HTMLDivElement>}
          className="w-full"
        >
          <div className="relative mb-10 flex flex-row items-end justify-between">
            <SectionHeading>
              <FormattedMessage defaultMessage="Footnotes" />
            </SectionHeading>

            <AnchorLink
              iconName="arrow-up"
              onClick={() =>
                transcriptRef.current &&
                handleScrollIntoView(transcriptRef.current as HTMLDivElement)
              }
            >
              <FormattedMessage defaultMessage="top" />
            </AnchorLink>
          </div>

          <div className="w-full">
            {footnotes.map(footnote => {
              const {
                timecode,
                author,
                category,
                text,
                title,
                date,
                linkUrl,
                annotation,
                source
              } = footnote;

              const time = timecode && timestampToSeconds(timecode);
              const paragraphCueTime =
                getParagraphCueTimeFromFootnote(footnote);

              return (
                <div
                  ref={el => {
                    footnoteRefs.current[timecode] = el;
                  }}
                  key={`${timecode}${author ? `-${author}` : ''}`}
                  className="mb-10"
                >
                  {time && (
                    <div className="mb-6 flex items-center justify-between">
                      <TimecodeButton
                        time={time}
                        onClick={e => handleSeek(e, time)}
                      />
                      {paragraphCueTime != null && (
                        <AnchorLink
                          onClick={() => {
                            paragraphRefs.current[
                              paragraphCueTime
                            ].scrollIntoView({ behavior: 'smooth' });
                          }}
                          className="ml-6"
                          iconName="arrow-up"
                        >
                          <FormattedMessage defaultMessage="Back" />
                        </AnchorLink>
                      )}
                    </div>
                  )}

                  {category && (
                    <div className="mb-6 text-sm capitalize text-gray-500">
                      {category}
                    </div>
                  )}

                  {text && (
                    <Text tag="p" variant="header4">
                      <Markdown>{text}</Markdown>
                    </Text>
                  )}

                  <div className="mb-2 flex flex-col gap-1">
                    {title && (
                      <Text tag="p" isBold>
                        <Markdown>{title}</Markdown>
                      </Text>
                    )}
                    {author && (
                      <div className="inline">
                        <Markdown>{author}</Markdown>
                        {date && <Markdown>{`, ${date}`}</Markdown>}
                      </div>
                    )}
                    {linkUrl && (
                      <Link className="underline" href={linkUrl}>
                        <Text tag="p">
                          <Markdown>{source}</Markdown>
                        </Text>
                      </Link>
                    )}
                  </div>

                  {annotation && (
                    <Text tag="p">
                      <Markdown>{annotation}</Markdown>
                    </Text>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Transcript;
