import cx from 'classnames';
import { useMemo } from 'react';
import shallow from 'zustand/shallow';

import { formatAsTimestamp } from 'lib/util';

import { Icon, Text } from 'components/@tedui';
import useVideoPlayerStore from 'components/video-player/store';

import { TimecodeButtonProps } from './TimecodeButton.props';

const TimecodeButton = ({
  time = 0,
  className = '',
  ...props
}: TimecodeButtonProps) => {
  const optimizedTime = useMemo(() => formatAsTimestamp(time), [time]);

  const { contentState } = useVideoPlayerStore(
    state => ({
      ...state
    }),
    shallow
  );

  const isAdBreak = contentState !== 'content';

  return (
    <button
      type="button"
      disabled={isAdBreak}
      className={cx(
        'group flex items-center justify-between rounded-full bg-gray-50 px-3 py-2',
        { 'w-20': !isAdBreak },
        className
      )}
      tabIndex={0}
      {...(!isAdBreak && {
        onClick: props.onClick
      })}
      {...props}
    >
      <div
        className={cx(
          'relative flex h-4 w-4 items-center justify-center text-red-500',
          {
            hidden: isAdBreak
          }
        )}
      >
        <Icon
          iconName="play-filled"
          className="absolute size-full opacity-0 group-hover:opacity-100"
        />
        <Icon iconName="play" className="absolute size-full" />
      </div>
      <Text variant="body2">{optimizedTime}</Text>
    </button>
  );
};

export default TimecodeButton;
