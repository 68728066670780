import Icon from 'components/@tedui/Icon';
import Avatar from 'components/avatar';
import Divider from 'components/divider';
import { Popover, positionBelow } from 'components/shared/Popover';
import type { RefObject } from 'react';
import { FormattedMessage } from 'react-intl';
import { useTalkPageContext } from '../talk-page-context';

interface TranscriptUser {
  avatar: {
    url?: string;
    generatedUrl: string;
  };
  name: {
    full?: string;
  };
  profilePath?: string;
}

interface TranslatorPopoverProps {
  targetRef: RefObject<HTMLElement | null>;
  translator: TranscriptUser;
  reviewer: TranscriptUser;
  isNativeLanguage: boolean;
  onClose: () => void;
}

export default function TranslatorPopover({
  targetRef,
  translator,
  isNativeLanguage,
  reviewer,
  onClose
}: TranslatorPopoverProps): React.ReactNode {
  const hasUserRows = translator || reviewer;
  const { panelStyleTokens } = useTalkPageContext();

  return (
    <Popover
      targetRef={targetRef}
      position={positionBelow}
      style={{ zIndex: panelStyleTokens.zIndex }}
    >
      <div className="flex rounded-sm bg-white px-5 py-3 shadow-xl">
        <div className="absolute right-3">
          <button type="button" onClick={onClose} className="block">
            <Icon iconName="x" className="hover:text-gray-500" />
          </button>
        </div>
        <div className="pr-5">
          {translator && (
            <UserRow
              user={translator}
              label={
                isNativeLanguage ? (
                  <FormattedMessage
                    tagName="span"
                    defaultMessage="Transcriber"
                  />
                ) : (
                  <FormattedMessage
                    tagName="span"
                    defaultMessage="Translator"
                  />
                )
              }
            />
          )}
          {reviewer && (
            <UserRow
              user={reviewer}
              label={
                <FormattedMessage tagName="span" defaultMessage="Reviewer" />
              }
            />
          )}
          {hasUserRows && <Divider className="mt-3" />}
          <div>
            <a
              href="https://www.ted.com/participate/translate"
              className="text-sm font-bold text-black hover:underline"
            >
              <FormattedMessage defaultMessage="Become a Translator" />{' '}
              <Icon iconName="arrow-right" />
            </a>
          </div>
        </div>
      </div>
    </Popover>
  );
}

const UserRow = ({
  user,
  label
}: {
  user: TranscriptUser;
  label: React.ReactNode;
}) => {
  return (
    <div className="mt-3 min-w-[24px]">
      <div className="flex items-center text-black">
        <Avatar
          src={user?.avatar?.url ?? user?.avatar?.generatedUrl}
          size={24}
          imgWidth={96}
          className="mr-2 h-6 w-6"
        />
        <div className="text-sm">
          <a href={user?.profilePath} className="font-bold hover:underline">
            {user?.name?.full},
          </a>{' '}
          {label}
        </div>
      </div>
    </div>
  );
};
