import { RefObject, useEffect, useRef } from 'react';

export default function useScrollTo<T extends HTMLElement>({
  when = true,
  options
}: {
  when?: boolean;
  options?: boolean | ScrollIntoViewOptions;
}): [RefObject<T | null>] {
  const scrollToRef = useRef<T | null>(null);
  useEffect(() => {
    if (scrollToRef.current) {
      if (when) {
        scrollToRef.current.scrollIntoView(options);
      }
    }
  }, [scrollToRef, when, options]);
  return [scrollToRef];
}
