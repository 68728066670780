import { Tabs } from 'components/tabs';

import PlaylistVideosList from '../playlist-videos';
import RelatedVideosList from './RelatedVideosList';
import SidebarVideosProps from './SidebarVideos.props';

import { useEffect, useState, useRef } from 'react';
import { TalkPageAds } from '../../../../ads';
import { useTalkPageContext } from '../talk-page-context';

import HybridRelatedVideosList from './RelatedVideosList/HybridRelatedVideosList';

const SidebarVideos = ({ id }: SidebarVideosProps) => {
  const { playlistSlug } = useTalkPageContext();
  const [tabName, setTabName] = useState('Watch next');

  enum tabIndices {
    WatchNext = 0,
    Playlist = 1
  }

  const standardDivRef = useRef<HTMLDivElement>(null);
  const personalizedDivRef = useRef<HTMLDivElement>(null);
  const hybridDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkVisibility = () => {
      const standardDiv = standardDivRef.current;
      const personalizedDiv = personalizedDivRef.current;
      const hybridDiv = hybridDivRef.current;

      if (standardDiv && !standardDiv.classList.contains('hidden')) {
        setTabName('Watch next');
      } else if (
        (personalizedDiv && !personalizedDiv.classList.contains('hidden')) ||
        (hybridDiv && !hybridDiv.classList.contains('hidden'))
      ) {
        setTabName('Recommended Talks');
      }
    };

    checkVisibility();

    const observer = new MutationObserver(checkVisibility);

    if (standardDivRef.current) {
      observer.observe(standardDivRef.current, {
        attributes: true,
        attributeFilter: ['class']
      });
    }
    if (personalizedDivRef.current) {
      observer.observe(personalizedDivRef.current, {
        attributes: true,
        attributeFilter: ['class']
      });
    }
    if (hybridDivRef.current) {
      observer.observe(hybridDivRef.current, {
        attributes: true,
        attributeFilter: ['class']
      });
    }

    return () => observer.disconnect();
  }, []);

  const initialIndex = playlistSlug
    ? tabIndices.Playlist
    : tabIndices.WatchNext;

  const [selectedIndex, setSelectedIndex] = useState(initialIndex);

  const tabItems = [
    {
      title: tabName,
      content: (
        <div className="mt-3">
          <div ref={standardDivRef} id="related-videos-standard">
            <RelatedVideosList id={id} />
          </div>
          <div
            ref={personalizedDivRef}
            id="related-videos-personalized"
            className="hidden h-0"
          >
            <HybridRelatedVideosList id={id} sailthruCount={6} />
          </div>

          <div
            ref={hybridDivRef}
            id="related-videos-hybrid"
            className="hidden h-0"
          >
            <HybridRelatedVideosList id={id} />
          </div>
        </div>
      )
    },
    ...(playlistSlug
      ? [
          {
            title: 'Playlist',
            content: (
              <div className="pt-6">
                <PlaylistVideosList slug={playlistSlug} talkID={id} />
              </div>
            )
          }
        ]
      : [])
  ];

  return (
    <div>
      <Tabs
        items={tabItems}
        selectedIndex={selectedIndex}
        onChange={setSelectedIndex}
        tabClassName="md:pl-4 md:pr-4 xl:pl-5 xl:pr-5"
        tabTextClassName="text-lg xl:text-2xl font-bold tracking-normal out whitespace-nowrap"
      />
      <TalkPageAds.TalkAdSideBarBTF />
    </div>
  );
};

export default SidebarVideos;
