import cx from 'classnames';
import { Link } from 'components/router';

import type TopicsListProps from './TopicsList.props';

const TopicsList = ({
  className,
  topics
}: TopicsListProps): React.ReactNode => {
  return (
    <ul className={cx('mb-6 inline-block', className)}>
      {topics.map(({ name, slug }) => (
        <li
          key={slug}
          className="mr-2 inline-block after:content-[','] last:mr-0 last:after:content-['_']"
        >
          <Link
            href={`/topics/${slug}`}
            className="inline-block py-1 text-tui-sm capitalize underline"
          >
            {name}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default TopicsList;
