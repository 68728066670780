// @ts-strict-ignore
import { crush } from '@tedconf/js-crushinator-helpers';

import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { Ratio } from 'components/@tedui';
import Image from 'components/image';
import { Microcopy, Text } from 'components/typography';

import { getDurationString } from 'lib/duration-helpers';
import getImageFromSet from 'lib/get-image-from-set';
import { useIsBreakpointWidth } from 'lib/hooks/useIsBreakpointWidth';

import SidebarVideoProps from './SidebarVideo.props';

const SidebarVideo = ({ video }: SidebarVideoProps) => {
  const { formatDate } = useIntl();
  const isMobileWidth = useIsBreakpointWidth({
    size: 'sm',
    breakPointType: 'tui'
  });
  const imageUrl = getImageFromSet(video?.primaryImageSet, '16x9');

  const loaderImg = useMemo(
    () => crush(imageUrl, { width: 10, quality: 50, blur: 0.75 }),
    [imageUrl]
  );

  const imageUrls = useMemo(() => {
    const imageSm = crush(imageUrl, { width: 400, quality: 75 });
    const imageLg = crush(imageUrl, { width: 1200, quality: 71 });
    return isMobileWidth ? imageSm : imageLg;
  }, [imageUrl, isMobileWidth]);

  return (
    <div className="group flex max-w-xl duration-500 ease-in-out hover:grayscale">
      <Ratio isAriaHidden>
        <div className="card-image relative h-full bg-gray-300 bg-cover bg-center transition-opacity">
          <Image
            alt={video.title}
            src={imageUrls}
            fill
            loading="lazy"
            blurDataURL={loaderImg}
            placeholder="blur"
            className="object-cover object-top"
          />
        </div>
        <Microcopy
          size="xs"
          className="absolute bottom-3 right-3 rounded-xs bg-black bg-opacity-70 pb-05 pl-1 pr-1 pt-05 font-bold text-white"
        >
          {getDurationString(video.duration)}
        </Microcopy>
      </Ratio>
      <div className="ml-4 flex w-full flex-col">
        <Microcopy size="s" className="mb-1 text-gray-900">
          {`${formatDate(video.publishedAt, {
            month: 'short',
            year: 'numeric'
          })}`}
        </Microcopy>
        <Text
          size="m"
          tagName="h4"
          className="mb-2 font-bold tracking-normal transition-colors group-hover:text-gray-700 xl:tracking-tight"
        >
          {video.title}
        </Text>
        <Microcopy size="s" className="text-gray-900">
          {video.presenterDisplayName}
        </Microcopy>
      </div>
    </div>
  );
};

export default SidebarVideo;
