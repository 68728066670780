import cx from 'classnames';
import { useRef } from 'react';
import {
  Tabs as PureTabs,
  Tab,
  TabList,
  TabPanel,
  TabPanels
} from '../shared/Tabs';

type TabsProps = {
  items: {
    title: string;
    content: React.ReactNode;
  }[];
  className?: string;
  tabListClassName?: string;
  tabClassName?: string;
  tabPanelClassName?: string;
  tabTextClassName?: string;
  onChange?: (index: number) => void;
  selectedIndex?: number;
};

const Tabs = ({
  items,
  className,
  tabListClassName,
  tabClassName,
  tabPanelClassName,
  tabTextClassName,
  onChange,
  selectedIndex
}: TabsProps) => {
  const tabListRef = useRef<HTMLDivElement>(null);

  return (
    <PureTabs
      className={cx('w-full', className)}
      onChange={onChange}
      index={selectedIndex}
      keyboardActivation="manual"
    >
      <div className="tabs-overflow-container">
        <TabList ref={tabListRef} className={tabListClassName}>
          {items.map((item, index) => (
            <Tab
              key={item.title}
              className={cx(
                'tab outline-inside group pl-3 pr-3 text-gray-500 transition-colors first:pl-0 last:pr-0 hover:text-gray-700 focus:text-gray-700',
                'group',
                tabClassName
              )}
              index={index}
            >
              <div
                className={cx(
                  'tab-text relative pb-2 group-aria-selected:font-bold group-aria-selected:text-textPrimary-onLight',
                  tabTextClassName
                )}
              >
                {item.title}
              </div>
            </Tab>
          ))}
        </TabList>
      </div>

      <TabPanels>
        {items.map((item, index) => (
          <TabPanel
            key={item.title}
            className={tabPanelClassName}
            index={index}
          >
            {item.content}
          </TabPanel>
        ))}
      </TabPanels>
    </PureTabs>
  );
};

export default Tabs;
