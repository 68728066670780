import { useTranscriptQuery } from 'api';
import { createLogger } from 'lib/logging';

interface UseValidatedTranscriptQueryProps {
  id?: string | null;
  language?: string;
  ssr?: boolean;
}

export function useValidatedTranscriptQuery({
  id,
  language,
  ssr = true
}: UseValidatedTranscriptQueryProps) {
  const logger = createLogger('TranscriptQuery');

  if (!id) {
    logger.warn('Transcript query attempted without ID');
    return {
      data: null,
      loading: false,
      error: new Error('ID is required for transcript query')
    };
  }

  if (!language) {
    logger.warn('Transcript query attempted without language');
    return {
      data: null,
      loading: false,
      error: new Error('Language is required for transcript query')
    };
  }

  return useTranscriptQuery({
    variables: { id, language },
    ssr,
    skip: !id || !language
  });
}
