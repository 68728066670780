import { last, noop } from 'f';
import { TranscriptQuery } from 'api';
import { useMemo } from 'react';

export type Paragraph = TranscriptQuery['translation']['paragraphs'][number];
export type Footnote =
  TranscriptQuery['video']['talkExtras']['footnotes'][number];

function convertTimecodeToMilliseconds(timecode: string): number {
  const [minutes, seconds] = timecode.split(':');
  return Number(minutes) * 60 * 1000 + Number(seconds) * 1000;
}

export function useLinkTranscript(
  paragraphs: Paragraph[],
  footnotes: Footnote[]
): {
  getFootnoteTimecodeFromParagraph(p: Paragraph): string;
  getParagraphCueTimeFromFootnote(f: Footnote): number;
} {
  return useMemo(() => {
    if (!paragraphs || !footnotes || footnotes.length === 0) {
      return {
        getFootnoteTimecodeFromParagraph: noop,
        getParagraphCueTimeFromFootnote: noop
      };
    }

    const paragraphToFootnoteMap = new Map<number, string>();
    const footnoteToParagraphMap = new Map<string, number>();

    paragraphs.forEach(paragraph => {
      const relatedFootnote = footnotes.find(footnote => {
        const footnoteTime = convertTimecodeToMilliseconds(footnote.timecode);

        return (
          footnoteTime >= paragraph.cues[0].time &&
          footnoteTime <= last(paragraph.cues).time
        );
      });

      if (!relatedFootnote) {
        return;
      }

      paragraphToFootnoteMap.set(
        paragraph.cues[0].time,
        relatedFootnote.timecode
      );

      footnoteToParagraphMap.set(
        relatedFootnote.timecode,
        paragraph.cues[0].time
      );
    });

    return {
      getFootnoteTimecodeFromParagraph(paragraph: Paragraph) {
        return paragraphToFootnoteMap.get(paragraph.cues[0].time);
      },
      getParagraphCueTimeFromFootnote(footnote: Footnote) {
        return footnoteToParagraphMap.get(footnote.timecode);
      }
    };
  }, [footnotes, paragraphs]);
}
