// TODO: align playlist videos with related videos https://tedconferences.atlassian.net/browse/WEB-482
import { crush } from '@tedconf/js-crushinator-helpers';
import cx from 'classnames';
import Router, { useRouter } from 'next/router';
import { range, splitAt } from 'rambda';
import React, { useCallback, type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSessionstorageState } from 'rooks';

import { usePlaylistVideosQuery } from 'api';

import { Ratio, Text } from 'components/@tedui';
import Divider from 'components/divider';
import Image from 'components/image';
import Loader from 'components/loaders';
import { Link } from 'components/router';
import SkeletonImage from 'components/skeleton-loader/image';
import useVideoPlayerStore from 'components/video-player/store';
import { editorialize } from 'lib/business';
import { buildTalkParams, buildTalkUrl } from 'lib/pages/talks/talkUrlUtils';

import { Video } from 'api';
import { TalkPageAds } from 'components/ads';
import getImageFromSet from 'lib/get-image-from-set';
import SidebarVideo from '../SidebarVideos/SidebarVideo';
import { useTalkPageContext } from '../talk-page-context';
import type PlaylistVideosListProps from './PlaylistVideosList.props';

const PlaylistVideosList = ({
  slug,
  talkID
}: PlaylistVideosListProps): React.ReactNode => {
  const { playlistSlug } = useTalkPageContext();
  const router = useRouter();
  const [isMuted] = useSessionstorageState('muted_state', false);

  const { expressPlaybackIntent, reset } = useVideoPlayerStore(state => ({
    expressPlaybackIntent: state.expressPlaybackIntent,
    reset: state.reset
  }));

  const { data, loading, error } = usePlaylistVideosQuery({
    variables: {
      slug
    },

    ssr: false
  });

  const image = data?.playlist?.primaryImageSet
    ? getImageFromSet(data.playlist.primaryImageSet, '1x1') ||
      getImageFromSet(data.playlist.primaryImageSet, '16x9')
    : null;

  const seasonNumber = data?.playlist?.seasonNumber;
  const playlistId = data?.playlist?.id || '';
  const nowPlayingClass = 'shadow-lg rounded-lg pointer-events-none';

  const videoLoaderList = (
    <>
      {range(0, 5).map(item => (
        <SkeletonImage
          key={`video-loader-${item}`}
          ratio={[16, 5]}
          className="mb-6"
        />
      ))}
    </>
  );

  /*
    Additional query params (in queryParams) can include items such as autoplay, subtitle, and language.
    Parse router.query and append specific keys.
    Playlist videos will always have a referrer param.
  */

  const playNextTalk = useCallback(
    (talkSlug, referrer) => () => {
      const currentUrl = router.asPath;

      // Create query params object explicitly
      const queryParams = {
        referrer,
        muted: isMuted ? 'true' : null,
        // Only add autoplay if both conditions are true
        autoplay: 'true'
      };

      const handleRouteChangeComplete = () => {
        const newUrl = router.asPath;

        if (newUrl !== currentUrl) {
          reset('nextTalk');
          expressPlaybackIntent(true);
        }

        router.events.off('routeChangeComplete', handleRouteChangeComplete);
      };

      router.events.on('routeChangeComplete', handleRouteChangeComplete);

      Router.push(
        {
          pathname: '/talks/[...talkSlug]',
          query: queryParams
        },
        `/talks/${talkSlug}${buildTalkParams(queryParams)}`
      );
    },
    [router, isMuted, reset, expressPlaybackIntent]
  );

  const renderVideoLink = (playlistVideo: Video): ReactElement => {
    const playing = talkID === playlistVideo.id;
    const referrer = `playlist-${slug}`;
    const { slug: talkSlug } = playlistVideo;

    // Create query params object explicitly
    const queryParams = {
      referrer,
      muted: isMuted ? 'true' : null,
      // Only add autoplay if both conditions are true
      autoplay: 'true'
    };

    const url = buildTalkUrl(`/talks/${talkSlug}`, queryParams);

    return (
      <Link
        key={playlistVideo.id}
        as={url}
        isZenithLink
        href="/talks/[slug]"
        onClick={playNextTalk(talkSlug, referrer)}
        className={cx('mb-5 block p-3', {
          [nowPlayingClass]: playing
        })}
      >
        <SidebarVideo video={playlistVideo} playing={playing} />
      </Link>
    );
  };

  const videos =
    data?.playlist?.videos?.nodes
      ?.filter((video): video is Video => video !== null)
      .map(renderVideoLink) ?? [];

  const [initialVideos, remainderVideos] = !loading
    ? splitAt(2, videos)
    : [[], []];

  if (error) {
    throw error;
  }

  return (
    <>
      <div className="mt-2 w-full pb-1">
        <Text tag="h3" variant="body1" isBold>
          {seasonNumber ? (
            <FormattedMessage defaultMessage="In this series" />
          ) : (
            <FormattedMessage defaultMessage="In this playlist" />
          )}
        </Text>
        <Divider className="mt-2" />
      </div>
      {slug && playlistId && (
        <div className="my-6 w-full border-b-thin border-black/12 pb-6">
          <Link
            href={`/playlists/${playlistId}/${slug}`}
            className="flex w-full items-center justify-between"
          >
            <div className="ml-1 w-full">
              {loading ? (
                <div className="w-full">
                  <Loader.Text
                    lines="2"
                    color={{
                      override: 'rgb(18 18 18 / 12%)'
                    }}
                  />
                </div>
              ) : (
                <div className="flex flex-col gap-1">
                  <Text
                    tag="h3"
                    variant="subheader1"
                    isBold
                    UNSAFE_className="tracking-[-1px]"
                  >
                    {editorialize(data?.playlist?.title || '')}
                  </Text>
                  <Text variant="body2" UNSAFE_className="underline">
                    {seasonNumber ? (
                      <FormattedMessage defaultMessage="More from this series" />
                    ) : (
                      <FormattedMessage defaultMessage="More from this playlist" />
                    )}
                  </Text>
                </div>
              )}
            </div>
            <div className="ml-6 h-[152px] min-w-[152px]">
              <Ratio ratio={[1, 1]} className="size-full">
                {loading ? (
                  <Loader.Ratio ratio={[1, 1]} color="rgba(18, 18, 18, 0.1)" />
                ) : (
                  image && (
                    <Image
                      src={crush(image || '', {
                        height: 200
                      })}
                      objectFit="cover"
                      objectPosition="center"
                      width={200}
                      height={200}
                      alt={playlistSlug || ''}
                    />
                  )
                )}
              </Ratio>
            </div>
          </Link>
        </div>
      )}

      {loading ? (
        videoLoaderList
      ) : (
        <>
          {initialVideos}
          {/* Playlist videos are the default view when playlist referrer query is present. Render ad unit here and not in related videos to avoid duplicate firing */}
          {playlistSlug && <TalkPageAds.TalkAdSideBarBTF />}
          {remainderVideos}
        </>
      )}
    </>
  );
};

export default PlaylistVideosList;
