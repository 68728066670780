import cx from 'classnames';
import Icon from 'components/@tedui/Icon';
import { Text } from 'components/typography';
import AnchorLinkProps from './AnchorLink.props';

const AnchorLink = ({
  onClick,
  iconName,
  className,
  children
}: AnchorLinkProps & { children: React.ReactNode }) => (
  <button
    type="button"
    className={cx(
      className,
      'group relative flex h-5 cursor-pointer items-center rounded-xs text-blue-500 ring-blue-700 ring-offset-4 hover:text-gray-500 focus-visible:text-gray-500 focus-visible:ring-2'
    )}
    onClick={onClick}
  >
    {/* Hidden text is used to prevent added width of bold text from making icon jump over on hover */}
    <Text size="m" className="font-bold tracking-tighter opacity-0">
      {children}
    </Text>
    <Text
      size="m"
      className="absolute border-b-thin group-hover:border-b-thicker group-hover:font-bold group-hover:tracking-tighter group-focus-visible:border-b-thicker group-focus-visible:font-bold group-focus-visible:tracking-tighter"
    >
      {children}
    </Text>
    {iconName && <Icon iconName={iconName} className="ml-1" />}
  </button>
);

export default AnchorLink;
