import type { ReactNode } from 'react';

import { map } from 'f';
import { noop } from 'lodash-es';

import ChevronIcon from 'icons/ChevronIcon';

export interface OptionType {
  label: string;
  value: string | number;
}

type SelectProps = {
  onChange?: (value: string) => void;
  options: Array<OptionType>;
  value: string;
  className?: string;
};

function Select({
  value,
  onChange = noop,
  options,
  ...props
}: SelectProps): React.ReactNode {
  return (
    <div className="relative w-full">
      <ChevronIcon
        variant="default"
        className="pointer-events-none absolute inset-y-1/2 right-4 h-2 -translate-y-1/2 text-[#717171]"
      />
      <select
        {...props}
        value={value}
        onChange={event => {
          const { value: targetValue } = event.target;
          onChange(targetValue);
        }}
        className="block w-full appearance-none rounded-sm border-thin border-solid border-black/12 bg-white px-3 py-2 text-tui-sm leading-tui-lg text-gray-900"
      >
        {map<OptionType, ReactNode>(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))(options)}
      </select>
    </div>
  );
}

export default Select;
