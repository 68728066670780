import cx from 'classnames';
import { debounce } from 'lodash-es';
import { memo, useCallback, useEffect } from 'react';
import shallow from 'zustand/shallow';

import { isElementInView } from 'lib/util';

import { Text } from 'components/@tedui';

import useVideoPlayerStore from 'components/video-player/store';
import type TranscriptParagraphProps from './TranscriptParagraph.props';

const TranscriptParagraph = ({
  activeCue,
  cues,
  rtl,
  language
}: TranscriptParagraphProps): React.ReactNode => {
  const { requestContentTime, onTranscriptClick, onSeek, contentState } =
    useVideoPlayerStore(
      state => ({
        ...state
      }),
      shallow
    );

  const isAdBreak = contentState === 'content';

  const handleSeekDebounced = debounce(() => {
    onTranscriptClick();
  }, 500);

  const handleSeek = useCallback((e, time) => {
    const seekTime = time;
    e.stopPropagation();
    handleSeekDebounced();
    requestContentTime(seekTime);
    onSeek(seekTime);
  }, []);

  useEffect(() => {
    const activeElement = document.querySelector(`.active-cue-${language}`);
    if (activeElement && !isElementInView(activeElement)) {
      activeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [activeCue, language]);

  return (
    <div className="w-full">
      <Text direction={rtl as 'rtl' | 'ltr'}>
        {cues.map((cue, idx) => {
          const isActiveCue = Number(cue.startTime) === activeCue;
          return (
            <div
              role="button"
              aria-disabled={!isAdBreak}
              key={`cue-${cue.startTime}-${cue.text}-${idx}`}
              aria-label={cue.text}
              onKeyDown={e => handleSeek(e, cue.startTime)}
              onClick={e => handleSeek(e, cue.startTime)}
              tabIndex={0}
              className={cx('inline', {
                [`active-cue-${language}`]: isActiveCue,
                'bg-yellow-500 bg-opacity-25': isActiveCue,
                'pointer-events-none': !isAdBreak,
                'cursor-pointer hover:bg-red-300': isAdBreak
              })}
            >
              <Text>{cue.text} </Text>
            </div>
          );
        })}
      </Text>
    </div>
  );
};

export default memo(TranscriptParagraph);
