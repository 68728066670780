// @ts-strict-ignore
import { RelatedVideosQuery, useRelatedVideosQuery } from 'api';
import { Link } from 'components/router';
import SkeletonImage from 'components/skeleton-loader/image';
import { useSetSourceContext } from 'lib/business/source-context';
import { useRouter } from 'next/router';
import { range, splitAt } from 'rambda';
import { useMemo } from 'react';

import { TalkPageAds } from 'components/ads';
import { useTalkPageContext } from '../../talk-page-context';
import SidebarVideo from '../SidebarVideo';
import RelatedVideosListProps from './RelatedVideosList.props';
import { usePlayNextTalk } from '../../hooks';

const RelatedVideosList = ({ id }: RelatedVideosListProps) => {
  const setSourceContext = useSetSourceContext('content_discovery_context');
  const { playNextTalk } = usePlayNextTalk({ setSourceContext });

  const router = useRouter();
  const { language, playlistSlug } = useTalkPageContext();

  const { data, loading, error } = useRelatedVideosQuery({
    variables: {
      id: Number(id),
      language,
      count: 6
    },
    ssr: false
  });

  const relatedVideos = useMemo(
    () =>
      data?.videos?.nodes[0]?.relatedVideos?.filter(video => video.id !== id) ||
      [],
    [data, id]
  );

  if (error) {
    console.error(error);
  } else if (loading) {
    return (
      <>
        {range(0, 5).map(item => (
          <SkeletonImage
            key={`video-loader-${item}`}
            ratio={[16, 5]}
            className="mb-6"
          />
        ))}
      </>
    );
  }

  const renderVideoLink = (
    relatedVideo: RelatedVideosQuery['videos']['nodes'][0]['relatedVideos'][0],
    index: number
  ): React.ReactNode => {
    const href = {
      pathname: router.pathname,
      query: {
        ...router.query,
        slug: relatedVideo.slug
      }
    };

    return (
      <div key={index}>
        <Link
          onClick={playNextTalk()}
          isZenithLink
          key={relatedVideo.slug}
          href={href}
          className={`block pr-3 ${index === 0 ? 'pt-0' : 'pt-3'} `}
        >
          <SidebarVideo video={relatedVideo} />
        </Link>
      </div>
    );
  };

  const [initialVideos, remainderVideos] = splitAt(
    2,
    relatedVideos.map(renderVideoLink) || []
  );

  return (
    <>
      {initialVideos}
      {/* When no playlist referrer query, render ad unit here otherwise render ad unit within playlist videos */}
      {!playlistSlug && <TalkPageAds.TalkAdSideBarBTF />}

      {remainderVideos}
    </>
  );
};

export default RelatedVideosList;
