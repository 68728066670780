import cx from 'classnames';
import { TalkPageAds } from 'components/ads';
import { Heading } from 'components/typography';
import { useIsBreakpointWidth } from 'lib/hooks/useIsBreakpointWidth';
import { VideoData } from 'lib/pages/talks/slug/[...slug].props';
import { FormattedMessage } from 'react-intl';
import { useMutationObserverRef } from 'rooks';
import SidebarVideos from '../SidebarVideos';
import TopicsList from '../TopicsList';
import TranscriptsWrapper from './TranscriptsWrapper';

interface Props {
  hasSidebarScrollbar: boolean;
  isTranscriptVisible: boolean;
  hasRelatedVideos: boolean;
  hasVideoTopics: boolean;
  hasTranslations: boolean;
  videoId: string;
  videoTopics: VideoData['topics']['nodes'];
  renderSidebarScrollbar: () => void;
  toggleTranscriptPanel: () => void;
}

const AsideSections = ({
  ref,
  hasSidebarScrollbar,
  isTranscriptVisible,
  hasRelatedVideos,
  hasVideoTopics,
  hasTranslations,
  videoId,
  videoTopics,
  renderSidebarScrollbar,
  toggleTranscriptPanel
}: Props & {
  ref: React.RefObject<HTMLDivElement | null>;
}) => {
  const [sidebar] = useMutationObserverRef(renderSidebarScrollbar);
  const isMobileWidth = useIsBreakpointWidth({
    size: 'md',
    breakPointType: 'tui'
  });

  return (
    <aside
      className={cx(
        'group/aside relative top-0 w-full lg:sticky lg:max-w-[425px] xl:max-w-[536px]'
      )}
      ref={sidebar}
    >
      <div
        className={cx(
          'lg:absolute lg:right-0 lg:top-0 lg:z-[1] lg:h-full lg:w-[17px] lg:bg-white',
          {
            'group-hover/aside:invisible group-hover/aside:opacity-0':
              hasSidebarScrollbar || isTranscriptVisible
          }
        )}
      />

      <div className="h-full pt-6 transition-all duration-1000 ease-in-out lg:sticky lg:top-0 lg:max-h-screen lg:overflow-y-scroll lg:pl-8 lg:pr-2 xl:pl-12 xl:pr-4">
        <div ref={ref}>
          <TalkPageAds.TalkAdSideBarATF />
          <div
            className={cx({
              hidden: isTranscriptVisible && !isMobileWidth
            })}
          >
            <div className="mb-6">
              {hasRelatedVideos && <SidebarVideos id={videoId} />}
            </div>
            {hasVideoTopics && (
              <div className="mb-6">
                <Heading size="s" className="mb-2 uppercase text-red-700">
                  <FormattedMessage defaultMessage="Related Topics" />
                </Heading>
                <TopicsList className="text-black" topics={videoTopics} />
              </div>
            )}
          </div>
        </div>
        <TranscriptsWrapper
          isVisible={isTranscriptVisible && hasTranslations}
          onToggle={toggleTranscriptPanel}
        />
      </div>
    </aside>
  );
};

export default AsideSections;
