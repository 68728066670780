import React, {
  useEffect,
  useRef,
  type CSSProperties,
  type RefObject
} from 'react';
import { createPortal } from 'react-dom';

export type Position = (
  targetRect: DOMRect | null,
  popoverRect: DOMRect | null
) => CSSProperties;

type PopoverProps = {
  children: React.ReactNode;
  targetRef: RefObject<HTMLElement | null>;
  position?: Position;
  className?: string;
  style?: CSSProperties;
  portal?: boolean;
};

export const Popover = ({
  children,
  targetRef,
  position = defaultPosition,
  className,
  style,
  portal = true
}: PopoverProps) => {
  const popoverRef = useRef<HTMLDivElement>(null);
  const [styles, setStyles] = React.useState<CSSProperties>({});

  useEffect(() => {
    const updatePosition = () => {
      const targetRect = targetRef.current?.getBoundingClientRect() || null;
      const popoverRect = popoverRef.current?.getBoundingClientRect() || null;

      const positionStyles = position(targetRect, popoverRect);
      setStyles(positionStyles);
    };

    updatePosition();

    window.addEventListener('resize', updatePosition);
    window.addEventListener('scroll', updatePosition, true);

    return () => {
      window.removeEventListener('resize', updatePosition);
      window.removeEventListener('scroll', updatePosition, true);
    };
  }, [position, targetRef]);

  const content = (
    <div
      ref={popoverRef}
      className={className}
      style={{
        position: 'absolute',
        ...style,
        ...styles
      }}
    >
      {children}
    </div>
  );

  if (portal) {
    return createPortal(content, document.body);
  }

  return content;
};

export const defaultPosition: Position = (targetRect, popoverRect) => {
  if (!targetRect || !popoverRect || typeof window === 'undefined') {
    return {};
  }

  const targetCenter = targetRect.left + targetRect.width / 2;
  const left = targetCenter - popoverRect.width / 2;

  return {
    left: `${left + window.scrollX}px`,
    top: `${targetRect.bottom + window.scrollY}px`
  };
};

export const positionRight: Position = (targetRect, popoverRect) => {
  if (!targetRect || !popoverRect || typeof window === 'undefined') {
    return {};
  }

  return {
    left: `${targetRect.right + window.scrollX}px`,
    top: `${targetRect.top + window.scrollY}px`
  };
};

export const positionLeft: Position = (targetRect, popoverRect) => {
  if (!targetRect || !popoverRect || typeof window === 'undefined') {
    return {};
  }

  return {
    left: `${targetRect.left - popoverRect.width + window.scrollX}px`,
    top: `${targetRect.top + window.scrollY}px`
  };
};

export const positionBelow: Position = (targetRect, popoverRect) => {
  if (!targetRect || !popoverRect || typeof window === 'undefined') {
    return {};
  }

  return {
    top: `${targetRect.top + targetRect.height + window.pageYOffset}px`,
    left: `${targetRect.right - popoverRect.width + window.pageXOffset}px`
  };
};
