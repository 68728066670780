import { Text } from 'components/@tedui';

import SectionHeadingProps from './SectionHeading.props';

const SectionHeading = ({ children }: SectionHeadingProps) => {
  return (
    <div className="w-full border-b-thin border-gray-300">
      <Text isBold variant="subheader2" tag="h3">
        {children}
      </Text>
    </div>
  );
};

export default SectionHeading;
